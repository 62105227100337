<template>
  <div
    v-if="data.type == 'Select'"
    v-show="shown"
    :class="['form-group', { hidden: hidden }, data.class]"
  >
    <label :for="data.property" class="form-label">{{ data.label }}</label>
    <span
      v-if="data.description"
      v-html="data.description"
      class="form-description"
    />
    <div class="select-group" :class="{ loading: data.loading }">
      <select
        :ref="data.property"
        :name="data.property"
        :id="data.property"
        v-model="data.value"
        :disabled="data.disabled || data.loading || hidden"
        @invalid.prevent="onInvalid"
        :required="data.required"
        :class="[
          'form-control',
          {
            empty:
              (!data.loading && data.options.length == 0 && !hidden) ||
              (!data.loading &&
                data.options.length > 0 &&
                !hidden &&
                !data.value),
          },
          { error: data.error },
        ]"
        v-on:change="setSelected"
      >
        <option
          v-if="data.loading && placeholder"
          :value="null"
          :label="placeholder"
          selected
          disabled
        >
          {{ placeholder }}
        </option>
        <option
          v-else-if="!data.loading && data.options.length == 0 && !hidden && !data.value"
          :value="null"
          label="Ingen valgmuligheder"
          selected
          disabled
        >
          Ingen valgmuligheder
        </option>
        <option
          v-else-if="!data.loading && data.options.length > 0 && !hidden && !data.value"
          :value="null"
          label="Vælg et punkt på listen"
          selected
          disabled
        >
          Vælg et punkt på listen
        </option>
        <option
          v-for="(option, index) in sortOptions(data.options, data.sort)"
          :key="index"
          :value="option.key"
          :label="option.value"
          :disabled="option.disabled"
        >
          {{ option.value }}
        </option>
      </select>
    </div>
    <div v-if="data.error && typeof data.error == 'string'" class="form-error">
      <span class="error-text">{{ data.error }}</span>
    </div>
  </div>

  <div v-else-if="data.type == 'checkbox'" v-show="shown" class="form-group" :class="[{ 'hidden': hidden }, data.classForm ]">
    <div class="input-group">
      <label class="checkbox" :class="{ error: data.error }">
        <input
          type="checkbox"
          v-model="data.value"
          :ref="data.property"
          :required="data.required"
          @change="onInput"
          @invalid.prevent="onInvalid"
        />
        <span class="checkmark"></span>
        <span class="description">{{ data.label }}</span>
      </label>
    </div>
    <transition name="hideFormGroup">
      <div v-if="data.error && typeof data.error == 'string'" class="form-error">
        <span class="error-text">{{ data.error }}</span>
      </div>
    </transition>
  </div>

  <div v-else-if="data.type == 'toggle'" v-show="shown" class="form-group" :class="[{ 'hidden': hidden }, { 'disabled': data.disabled }, data.classForm ]">
    <div class="input-group">
      <FlipSwitch
        :enabled="!data.disabled"
        :label="data.label"
        v-model="data.value"
        @input="onInput"
      />
    </div>
  </div>

  <div v-else-if="data.type == 'radio'" v-show="shown" class="form-group" :class="[{ 'hidden': hidden }, data.classForm ]">
    <label class="form-label">{{ data.label }}</label>
    <span v-if="data.description" class="form-description">{{ data.description }}</span>
    <div class="radio-buttons">
      <template v-for="(option, index) in data.options">
        <div class="radio-wrapper" :key="index">
          <input
            type="radio"
            :name="data.property"
            :id="`${data.property}-${option.value}`"
            :value="option.value"
            :disabled="option.disabled"
            required
            v-model="data.value"
            @change="
              data.selected = option;
              onInput();
            "
          />
          <label :for="`${data.property}-${option.value}`" class="radio-button" :class="[{ disabled: option.disabled }, { 'with-icon': data.icon }]">
            <span v-if="option.headline" class="headline" v-html="option.headline" />
            <span v-if="option.subline" class="subline" v-html="option.subline" />
          </label>
        </div>
      </template>
    </div>
  </div>

  <div v-else-if="data.type == 'radio2'" v-show="shown" class="form-group" :class="[{ 'hidden': hidden }, data.classForm ]">
    <div style="margin-bottom: 10px;">
      <label class="form-label">{{ data.label }}</label>
      <span v-if="data.description" class="form-description" style="margin-bottom: 0;">{{ data.description }}</span>
      <radio-view style="margin: 6px 40px 0 0;" v-for="(option, idx) in data.options" :key="`radio-view-${idx}`" v-model="data.value" :rvalue="option.value" :disabled="data.disabled">{{ option.headline }}</radio-view>
      <input
        v-show="false"
        type="text"
        v-model="data.value"
        :ref="data.property"
        :required="data.required"
        @invalid.prevent="onInvalid"
        tabindex="-1"
      />
    </div>
    <transition name="hideFormGroup">
      <div v-if="data.error && typeof data.error == 'string'" class="form-error">
        <span class="error-text">{{ data.error }}</span>
      </div>
    </transition>
  </div>

  <p v-else-if="!('type' in data)" v-show="shown" class="form-group" :class="[{ 'hidden': hidden }, data.classForm ]" style="margin-bottom: 10px;">
    {{ data.description }}
  </p>

  <div
    v-else
    v-show="shown"
    :class="[
      'form-group',
      data.class,
      { hidden: hidden },
      { invisible: data.type == 'Hidden' },
    ]"
  >
    <label v-if="data.label && data.type != 'Hidden'" :for="data.property" class="form-label">
      {{ data.label }}
    </label>
    <span
      v-if="data.description"
      v-html="data.description"
      class="form-description"
    />
    <div
      class="input-group"
      ref="inputGroup"
      :class="[data.type, { prefix: data.prefix }]"
    >
      <span
        v-if="data.prefix"
        class="input-group-prefix"
        v-html="data.prefix"
        @click="$refs[data.property].focus()"
      />
      <input
        v-model="data.value"
        :type="data.type"
        :name="data.property"
        :id="data.property"
        :ref="data.property"
        :disabled="data.disabled || data.loading"
        :required="data.required"
        :readonly="data.readonly"
        :pattern="data.pattern"
        :inputmode="data.inputmode"
        :min="data.min"
        :max="data.max"
        :minlength="data.minlength"
        :maxlength="data.maxlength"
        :placeholder="data.placeholder"
        :autofocus="data.autofocus"
        :tabindex="hidden ? -1 : 0"
        :autocomplete="data.autocomplete || 'off'"
        :list="data.datalist ? `${data.property}-datalist` : null"
        @input="onInput"
        @invalid.prevent="onInvalid"
        @blur="onBlur"
        @keydown.enter.prevent="onEnter"
        @focus="isFocused = true"
        @keydown.down.prevent="onDownArrow"
        @keydown.tab="isFocused = false"
        :class="['form-control', { error: data.error }]"
      />
      <button
        v-if="data.button"
        :type="data.button.type"
        class="button"
        :disabled="data.loading || !data.value"
        @click.prevent="$emit('formFunction', data.button.onClick)"
      >
        <span v-if="data.loading">
          <i class="fa-light fa-spinner-third fa-fw icon-spin"></i>
        </span>
        <span v-else v-html="data.button.label"></span>
      </button>

      <!-- Search options -->
      <div v-if="data.type == 'search' && data.options && !data.selected && data.value && isFocused" ref="optionList" class="input-list scrollbar">
        <a v-if="data.options.length == 0" class="input-list-option disabled">Ingen valgmuligheder</a>
        <template v-if="data.options.length">
          <a
            v-for="(option, index) in data.options"
            tabindex="0"
            :key="index"
            @click="chooseOption(option)"
            @keydown="$refs[data.property].focus()"
            @keydown.enter.prevent="chooseOption(option)"
            @keydown.up.prevent="onOptionUpArrow"
            @keydown.down.prevent="onOptionDownArrow"
            @keydown.tab="isFocused = false"
            class="input-list-option"
            v-html="highlightText(data.value, data.optionValue ? option[data.optionValue] : option.value)"
          />
        </template>
      </div>
    </div>
    <transition name="hideFormGroup">
      <div v-if="data.error && typeof data.error == 'string'" class="form-error">
        <span class="error-text">{{ data.error }}</span>
      </div>
    </transition>
    <datalist v-if="data.datalist" :id="`${data.property}-datalist`">
      <option
        v-for="(option, index) in data.datalist"
        :key="index"
        :value="option.value"
        :label="option.label"
      >
        {{ option.label }}
      </option>
    </datalist>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import FlipSwitch from "./FlipSwitch.vue";
import RadioView from "./RadioView.vue";

export default {
  name: "FormInput",
  components: {
    FlipSwitch,
    RadioView,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      counter: 0,
      autocomplete: null,
      isFocused: false,
      /*
       * Get options function in data, so that debounce works on individual components
       */
      getOptions: debounce(() => {
        let self = this;

        // Reset error on field
        self.data.error = null;

        // If dependencies are not met remove value and return from function
        if (!self.dependenciesMet) {
          return false;
        }

        // Set data to loading
        self.data.loading = true;

        // Start counter
        self.counter = 0;
        let counterFunction = setInterval(function () {
          self.counter++;
        }, 1000);

        // Create api string
        let apiString = self.data.api;

        // Return if no apiString
        if (!apiString) return;

        // Replace brackets with dynamic data
        apiString = self.parseBraces(apiString, self.product.fields);

        if (apiString.includes("?") && !apiString.includes("dataforsyningen")) {
          apiString += `&preventCache=${self.dayjs().unix()}`;
        } else {
          apiString += `?preventCache=${self.dayjs().unix()}`;
        }

        // Cannot end on equals
        if (apiString.substring(apiString.length - 1) == "=") {
          this.cerror("Could not get options");
          self.data.loading = false;
          return;
        }

        // Get options list
        self.axios
          .get(apiString, {
            headers: {
              "x-ws-key": process.env.VUE_APP_KEY,
              Authorization: `Bearer ${self.$store.state.token}`,
            },
          })
          .then(function (response) {
            self.data.options = response.data;
            self.data.loading = false;

            // If the field has dependencies, and
            if (!response.data.length && self.data.dependencies) {
              self.data.error = "Ingen valgmuligheder fundet. Vælg en anden mulighed på ovenstående felt, for at få valgmuligheder.";
              self.onInvalid(self.data);
            }
            clearInterval(counterFunction);
          })
          .catch(function () {
            self.data.loading = false;
            clearInterval(counterFunction);
          });
      }, 250),
    };
  },
  created() {
    if (this.data === null) return;

    // Get options if the type is select box
    if (
      this.data.type != "Search" &&
      this.data.api &&
      (this.data.options == null || this.data.options.length == 0)
    ) {
      this.getOptions();
    }

    // Set watchers if the field has any
    if (this.data.dependencies && this.data.dependencies.length > 0) {
      this.setDependencies();
    }
  },
  mounted() {
    document.addEventListener("click", this.closeOptions);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeOptions);
  },
  computed: {
    /*
     * Selected chosen option
     */
    chosenOption() {
      // If not options is empty, return null
      if (
        this.data === null ||
        !this.data.options ||
        this.data.options.length == 0 ||
        this.data.value == null
      ) {
        return null;
      }
      // Find the label of the chosen option
      let option = this.data.options.find(
        (option) => option.key == this.data.value
      );

      // If you have found option, return it, else return null
      if (option) {
        return option;
      } else {
        return null;
      }
    },
    /*
     * Should the form be hidden from requires attribute (Opacity)
     */
    hidden() {
      let hidden = false;
      if (this.data.requires) {
        this.data.requires.forEach((requirement) => {
          if (!(requirement in this.product.fields)) {
            this.cerror("Required not found", requirement);
            return;
          }

          if (this.product.fields[requirement].value === null || typeof this.product.fields[requirement].value == 'undefined') {
            hidden = true;
          }
        });
      }
      return hidden;
    },
    /*
     * Computed property for defining whether form should be shown
     */
    shown() {
      // If the data type is hidden return false
      if (this.data.type == "Hidden") return false;

      let shown = true;

      // Loop through conditions, and check if value is set
      if (this.data.conditions) {
        this.data.conditions.forEach((condition) => {
          if (!this.product.fields[condition].value) {
            shown = false;
          }
        });
      }

      return shown;
    },
    /*
     * Computed property for checking if dependencies are met
     */
    dependenciesMet() {
      let dependenciesMet = true;

      // If there are no dependencies or the array is empty, return true
      if (!this.data.dependencies || this.data.dependencies.length == 0) {
        return true;
      }

      this.data.dependencies.forEach((dependency) => {
        if (this.product.fields[dependency].value == null) {
          dependenciesMet = false;
        }
      });

      return dependenciesMet;
    },
    /*
     * Computed property for checking if dependencies are met
     */
    placeholder() {
      if (!this.data.loading) {
        return null;
      } else if (this.data.loading && this.counter < 5) {
        return "Henter valgmuligheder";
      } else if (this.data.loading && this.counter < 10) {
        return "Henter stadig valgmuligheder";
      } else {
        return "Leder stadig efter valgmuligheder";
      }
    },
  },
  watch: {
    /*
     * If you deselect option, remove selected field
     */
    chosenOption(value) {
      // Set selected to chosen option
      this.data.selected = value;
    },
    /*
     * If you unfocus without choosing af value
     */
    isFocused(value) {
      // Search api if this function is needed
      if (this.data.type == "search" && this.data.value && !this.data.selected && !value) {
        this.data.error = "Vælg venligst en valgmulighed fra listen.";
      }
    },
    /*
     * If the value changes
     */
    "data.value"() {
      // Reset error
      this.data.error = null;

      // Search api if this function is needed
      if (this.data.type == "search" && this.data.api && this.data.value) {
        this.searchOptions();
      }
    },
  },
  methods: {
    extractInt(str) {
      let r = '';

      for (let i = 0; i < str.length; ++i) {
        if (!isNaN(str[i])) r += str[i];
      }

      return parseInt(r);
    },
    sortOptions(options, sort) {
      if (!sort) return options;

      const self = this;
      let opts = Array.from(options);

      if (sort == 'value') opts.sort((a, b) => { return parseInt(a.key) - parseInt(b.key); });
      if (sort == 'label_extract_int') opts.sort((a, b) => { return self.extractInt(a.value) - self.extractInt(b.value); });

      return opts;
    },
    /**
     * Set selected with debounce
     */
    setSelected: debounce(function () {
      if (this.data.onInput) {
        this.$emit("formFunction", this.data.onInput);
      }
    }, 250),
    /*
     * Function for setting watchers and disabled dependencies for field
     */
    setDependencies() {
      let self = this;

      self.data.dependencies.forEach((dependency) => {
        self.$watch(`product.fields.${dependency}.value`, {
          deep: false,
          handler: self.getOptions,
        });
      });
    },
    /*
     * Search API
     */
    searchOptions: debounce(function () {
      let self = this;

      // Do not search if empty or under 3 chars
      if (!self.data.value.toString().length) {
        self.data.options = [];
        this.cerror("Did not search");
        return;
      }

      // Create api string
      let apiString = `${self.data.api}${encodeURIComponent(self.data.value)}`;

      this.clog(apiString);

      // Get options list
      self.axios
        .get(apiString, {
          headers: {
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then(function (response) {
          if (response.data) {
            self.data.options = response.data.slice(0, 30);
          }
        })
        .catch(function () {
          self.data.options = [];
        });
    }, 200),
    /*
     * Function to choose option
     */
    chooseOption(option) {
      this.data.selected = option;
      this.data.value = this.data.optionValue ? option[this.data.optionValue] : option.value;
      this.isFocused = false;

      // If on select event is set, trigger form function
      if (this.data.onSelect) {
        this.$emit("formFunction", this.data.onSelect, option);
      }
    },
    /*
     * Close function
     */
    closeOptions(event) {
      let el = this.$refs.inputGroup;
      if (el && el !== event.target && !el.contains(event.target)) {
        this.isFocused = false;
      }
    },
    /*
     * Function to run on input
     */
    onInput() {
      // Remove selected on input in search
      if (this.data.type == "search") {
        this.data.selected = null;
      }

      // If function should be emitted, do it here
      if (this.data.onInput) {
        this.$emit("formFunction", this.data.onInput, this.data.value);
      }
    },
    /*
     * Function to run on invalid
     */
    onInvalid(event) {
      // If field is hidden, skip  invalid
      if (this.hidden) return;

      if (this.data.onInvalid) {
        this.$emit("formFunction", this.data.onInvalid, this.data.value);
        return;
      }

      // Insert error
      if (this.data.validationMessage) {
        this.data.error = this.data.validationMessage;
      } else if (event.target.validationMessage) {
        this.data.error = event.target.validationMessage;
      } else {
        this.data.error = "Udfyld dette felt.";
      }

      if (this.data.property && this.$refs[this.data.property]) {
        this.$refs[this.data.property].focus();
      }
    },
    /*
     * Function to run on enter key
     */
    onEnter() {
      if (this.data.onEnter) {
        this.$emit("formFunction", this.data.onEnter);
      }
    },
    /*
     * Function to run on invalid
     */
    onBlur() {
      if (this.data.onBlur) {
        this.$emit("formFunction", this.data.onBlur);
      }
    },
    /*
     * Function if you press down arrow on input element
     */
    onDownArrow() {
      let el = this.$refs.optionList;
      if (el) {
        el.firstElementChild.focus();
      }
    },
    /*
     * Function if you press up arrow on option element
     */
    onOptionUpArrow(e) {
      if (e && e.target) {
        if (e.target.previousElementSibling && e.target.previousElementSibling.tagName == "A") {
          e.target.previousElementSibling.focus();
        }
      }
    },
    /*
     * Function if you press down arrow on option element
     */
    onOptionDownArrow(e) {
      if (e && e.target) {
        if (e.target.nextElementSibling && e.target.nextElementSibling.tagName == "A") {
          e.target.nextElementSibling.focus();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
