<template>
  <div
    :class="{ 'radio-view': true, active: _active, disabled: disabled }"
    @click="$emit('input', rvalue)"
  >
    <div class="circle">
      <i>&nbsp;</i>
      <i v-show="_active" class="fa-light fa-check"></i>
      <i>&nbsp;</i>
    </div>
    <div class="label">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, String, Number],
      default: null,
    },
    rvalue: {
      type: [Boolean, String, Number],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    _active() {
      return this.value == this.rvalue;
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-view {
  display: inline-grid;
  grid-template-columns: 40px minmax(0, 1fr);
  grid-template-rows: 30px;
  cursor: pointer;

  & > .circle {
    width: 30px;
    height: 30px;
    border: 1px solid #E0E0E0;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    color: white;
    background-color: white;

    & > i {
      border: none;
      margin: none;
    }
  }

  & > .label {
    align-self: center;
    white-space: nowrap;
  }

  &.active {
    & > .circle {
      background-color: #3f3666;
      border-color: #3f3666;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
