<template>
  <label
    :class="{ switch: true, checked: value, disabled: !enabled }"
    @click="$emit('input', !value)"
  >
    <span class="slider"></span>
    <span class="switch-label" :class="{ chosen: label }">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "FlipSwitch",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  }
};
</script>
